import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setSelectedLiveDesktopSport } from 'stores/match';
// import Loading from 'components/general/loading.js';
import { AWS_BUCKET_URL } from 'toolkit/constants';
import MatchCard from 'components/general/match_card.js';
import { eventsToGroup } from 'toolkit/eventsToGroup';
import { sportIdToSportType } from 'toolkit/utils';
import TopSidebar from './top_sidebar';

const LiveMatchContainer = (props) => {
  const dispatch = useDispatch();
  const { onMatchTrackerSet, onMatchDetailIdSet } = props;
  const liveEvents = useSelector(
    (state) => {
      const { liveMatches, events } = state.match;
      return liveMatches.map((c) => events[c]).filter((l) => l);
    },
    (a, b) => a.length === b.length
  );
  const selectedLiveSportDesktop = useSelector((state) => state.match.selectedLiveSportDesktop);
  const selectedLocale = useSelector((state) => state.match.selectedLocale);
  const locales = useSelector((state) => state.match.locales);
  const { t } = useTranslation('translations');
  const [sports, setSports] = useState({});

  useEffect(() => {
    const groups = eventsToGroup(liveEvents);
    setSports(groups);
    if (selectedLiveSportDesktop === null && liveEvents.length > 0) {
      const type =
        selectedLiveSportDesktop !== null ||
        sports.hasOwnProperty(Number.parseInt(selectedLiveSportDesktop)) === true
          ? selectedLiveSportDesktop
          : Object.keys(groups)[0];
      dispatch(setSelectedLiveDesktopSport(type));
    }
  }, [selectedLiveSportDesktop, liveEvents]);

  const defaultMarkets = {
    0: [1, 18, 8],
    1: [219, 225, 223],
    2: [186, 189, 187],
    3: [219, 223, 225],
    4: [251, 258, 256],
    5: [340, 605],
    6: [186, 314, 188],
    7: [1, 18],
    8: [1, 18, 16],
    9: [1, 18, 14],
    10: [1, 18, 16],
    11: [186, 494, 493],
    12: [186, 238, 237],
    13: [186, 238, 202],
    14: [186, 18]
  };

  const title = () => {
    return t(`sportType.${sportIdToSportType(selectedLiveSportDesktop)}`);
  };

  function getBets() {
    if (!sports[selectedLiveSportDesktop]) return null;
    return sports[selectedLiveSportDesktop]
      .filter((l) => l.matches && l.matches.length > 0)
      .map((leagueGroup, index) => {
        return (
          <div
            className="mb-3 d-flex flex-column gap-2"
            key={leagueGroup.category + leagueGroup.league + index}>
            <div className="desktop-league-header h5 fw-bold">
              <span className="me-2">
                <img width="16" height="16" src={`${AWS_BUCKET_URL}/Icons/99.svg`} />
              </span>
              <span className="text-white">{leagueGroup.category}</span>
              <span className="me-2 ms-2 fw-bold text-truncate">{leagueGroup.league}</span>
              {index === 0 && (
                <div className="ms-auto bets-header-wrapper">
                  {defaultMarkets[selectedLiveSportDesktop].map((m) => (
                    <span className="bet-group-header" key={m}>
                      {t('markets.' + m)}
                    </span>
                  ))}
                </div>
              )}
            </div>
            {leagueGroup.matches.map((match) => {
              const liveEvent = liveEvents.find((le) => le.providerId === match.providerId);
              if (!liveEvent) return null;
              return (
                <MatchCard
                  key={liveEvent.providerId}
                  providerId={liveEvent.providerId}
                  sport={selectedLiveSportDesktop}
                  path={location.pathname}
                  locales={locales}
                  selectedLocale={selectedLocale}
                  desktop={true}
                  desktopMarkets={defaultMarkets[selectedLiveSportDesktop]}
                  hideMarketLabel={true}
                  onMatchTrackerSet={onMatchTrackerSet}
                  onMatchDetailIdSet={onMatchDetailIdSet}
                />
              );
            })}
          </div>
        );
      });
  }
  return (
    <div>
      <div xs={8} className="filter-sidebar">
        <TopSidebar />
      </div>
      <hr />
      <div className="desktop-league">{getBets()}</div>
    </div>
  );
};

export default LiveMatchContainer;
