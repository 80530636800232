import { useState, useEffect } from 'react';
import { Container, Tab, Nav } from 'react-bootstrap';
import VivoGames from './VivoGames';
import EvoGames from './EvoGames';
import { useSearchParams } from 'react-router-dom';

import './index.scss';

export default function CasinoBase() {
  const [searchParams] = useSearchParams();
  const [key, setKey] = useState('vivo');

  useEffect(() => {
    if (searchParams.has('home')) {
      setKey('evo');
    }
  }, [searchParams]);

  return (
    <Container style={{ maxWidth: '100%' }}>
      <div className="row py-2 gy-3" style={{ backgroundColor: '#1d1d27', color: 'white' }}>
        <div className="col-12">
          <Tab.Container
            id="casino-tab"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
            fill
            variant="pills">
            {!searchParams.has('game') && (
              <Nav variant="pills" className="mb-2">
                <Nav.Item className="w-50">
                  <Nav.Link eventKey="vivo">Vivo</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item className="w-50">
                  <Nav.Link eventKey="evo">Evo</Nav.Link>
                </Nav.Item> */}
              </Nav>
            )}

            <Tab.Content>
              {key === 'vivo' && (
                <Tab.Pane eventKey="vivo">
                  <VivoGames />
                </Tab.Pane>
              )}
              {/* <Tab.Pane eventKey="evo">
                <EvoGames />
              </Tab.Pane> */}
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </Container>
  );
}
