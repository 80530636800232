/* eslint-disable no-case-declarations */
import { Fragment } from 'react';
import { Row, Col, Container, Stack } from 'react-bootstrap';
import * as _ from 'lodash';
import {
  MarketIdToMarketName,
  ReverseName,
  OutcomeName,
  emptyMarketLabels,
  getFootballOddTypeText
} from 'toolkit/utils';
import { AWS_BUCKET_URL } from 'toolkit/constants';
import { EnumMarketName } from 'toolkit/Enums.js';
import BetBox from './bet_box';
import { useMediaQuery } from 'react-responsive';

function BetTitleAndBox(props) {
  const { market, marketId, matchId, hideMarketLabel } = props;
  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)'
  });
  function compareById(a, b) {
    return parseInt(a.id) - parseInt(b.id);
  }

  function getMarkets() {
    // const oddsSize = market.outcomes?.length === 2 ? 6 : 4;
    const oddsSize = 4;

    let sortedOutcomes;
    if (market.id == EnumMarketName.WINNERBOTHTEAMSTOSCORE_35) {
      const outcomeIds = ['78', '82', '86', '80', '84', '88'];
      sortedOutcomes = outcomeIds
        .map((id) => market.outcomes.find((o) => o.id === id))
        .filter((o) => o !== undefined);
    } else {
      sortedOutcomes = [...(market.outcomes || [])].sort(compareById);
    }

    //TODO market.id is not string!!!! it is number below cases is not working, check if we realy need cases
    switch (market.id) {
      case EnumMarketName.NEXTGOAL_8:
        return (
          <Container>
            <Row className="">
              {!hideMarketLabel && (
                <Col xs={6} className={`fs-7 ${isDesktop ? 'text-white' : 'text-dark'}`}>
                  <div>&nbsp;</div>
                  {MarketIdToMarketName(market.id, market.stringId)}
                </Col>
              )}
              <Col xs={hideMarketLabel ? 12 : 6}>
                <Row className="gy-2 gx-1">
                  {_.orderBy(market.outcomes, ['id'], ['asc']).map((outcome, index) => (
                    <Col
                      xs={4}
                      className=""
                      key={market.id + ' ' + (outcome.id || matchId + '' + index)}>
                      <BetBox
                        providerId={matchId}
                        outcomeId={outcome.id}
                        marketStringId={market.stringId}
                        sport={props.sportType}
                        path={props.path}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        );
      case EnumMarketName.NEXTGOALSCORER_38:
      case EnumMarketName.LASTGOALSCORER_39:
      case EnumMarketName.ANYTIMEGOALSCORER_40:
        return (
          <Stack direction="horizontal" gap={1}>
            {market.outcomes
              .filter((o) => o.odds !== 0)
              .map((outcome, index) => (
                <Col
                  key={market.id + ' ' + (outcome.id || matchId + '' + index)}
                  xs={2}
                  className=" float-end">
                  <BetBox
                    providerId={matchId}
                    outcomeId={outcome.id}
                    marketStringId={market.stringId}
                    showOddTitle={false}
                    sport={props.sportType}
                    path={props.path}
                  />
                </Col>
              ))}
          </Stack>
        );
      case EnumMarketName.CORRECTSCORE_41:
        const homeScore = [];
        const draw = [];
        const awayScore = [];
        market.outcomes.forEach((outcome, index) => {
          const outcomeName = outcome.name.split(':');
          const targetArray =
            Number(outcomeName[0]) > Number(outcomeName[1])
              ? homeScore
              : outcomeName[0] === outcomeName[1]
              ? draw
              : awayScore;
          targetArray.push(
            <Stack
              key={market.id + ' ' + (outcome.id || matchId + '' + index)}
              // gap={1}
              className="flex-grow-0">
              <BetBox
                providerId={matchId}
                outcomeId={outcome.id}
                marketStringId={market.stringId}
                sport={props.sportType}
                path={props.path}
              />
            </Stack>
          );
        });
        return (
          <>
            <Col className={`fs-7 ${isDesktop ? 'text-white' : 'text-dark'}`} xs={6}>
              <div className="mb-1">&nbsp;</div>
              {MarketIdToMarketName(market.id, market.stringId).split(' + ')[0]}
            </Col>
            <Col xs={6}>
              <Container>
                <Row className="gx-1">
                  <Col xs={4} className={'d-flex flex-column gap-2'}>
                    {homeScore}
                  </Col>
                  <Col xs={4} className={'d-flex flex-column gap-2'}>
                    {draw}
                  </Col>
                  <Col xs={4} className={'d-flex flex-column gap-2'}>
                    {awayScore}
                  </Col>
                </Row>
              </Container>
            </Col>
          </>
        );
      // case EnumMarketName.CORRECTSCORE_41:
      //   return (
      //     <Container>
      //       <Row className="">
      //         <Col xs={6} className="text-dark fs-7">
      //           <div className="mb-1">&nbsp;</div>
      //           {MarketIdToMarketName(market.id, market.stringId).split(' + ')[0]}
      //         </Col>
      //         <Col xs={6}>
      //           <Row className="gy-2 gx-1 justify-content-end">
      //             {[...market.outcomes].sort(compareById).map((outcome, index) =>
      //               formatOdd(outcome?.odds) === '1.00' ? null : (
      //                 <Col
      //                   key={market.id + ' ' + (outcome.id || matchId + '' + index)}
      //                   xs={oddsSize}
      //                   className="">
      //                   <BetBox
      //                     providerId={matchId}
      //                     outcomeId={outcome.id}
      //                     marketStringId={market.stringId}
      //                     sport={props.sportType}
      //                     path={props.path}
      //                   />
      //                 </Col>
      //               )
      //             )}
      //           </Row>
      //         </Col>
      //       </Row>
      //     </Container>
      //   );
      case EnumMarketName.HALFTIMEFULLTIMETOTAL_818:
      case EnumMarketName.HALFTIMEFULLTIMEFIRSTHALFTOTAL_819:
        const groupedMarkets = _.values(
          _.groupBy(market.outcomes, (x) => {
            if (x.id >= 1845) return x.id;

            return Number(x.id) + 9;
          })
        );
        return (
          <Container>
            <Row className={`ps-2 mt-1 ${isDesktop ? 'text-white' : 'text-dark'}`}>
              {MarketIdToMarketName(market.id, market.stringId)}
            </Row>
            {groupedMarkets.map((outcome, index) => (
              <Row className="m-0" key={market.id + ' ' + (outcome.id || matchId + '' + index)}>
                <Col
                  xs={6}
                  className={`ps-1 pe-1 text-center align-item-center justify-content-center pb-2 pt-0 mt-4 ${
                    isDesktop ? 'text-white' : 'text-dark'
                  }`}>
                  {
                    getFootballOddTypeText(
                      market.id.toString(),
                      outcome[0].id.toString(),
                      outcome[0].name
                    ).split(' ')[0]
                  }
                </Col>
                <Col xs={6}>
                  <Row>
                    <Col xs={6} className="">
                      <BetBox
                        providerId={matchId}
                        outcomeId={outcome[0].id}
                        marketStringId={market.stringId}
                      />
                    </Col>
                    {outcome.length > 1 && (
                      <Col xs={6} className="">
                        <BetBox
                          providerId={matchId}
                          outcomeId={outcome[1].id}
                          marketStringId={market.stringId}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            ))}
          </Container>
        );
      case EnumMarketName.HALFTIMEFULLTIMEEXACTGOALS_820:
        const groupedMarkets2 = _.values(
          _.groupBy(market.outcomes, (x) => {
            return x.name.split('&')[0];
          })
        );
        return (
          <Container>
            <Row className={`ps-2 mt-n3 ${isDesktop ? 'text-white' : 'text-dark'}`}>
              <div>&nbsp;</div>
              {MarketIdToMarketName(market.id, market.stringId)}
            </Row>
            {groupedMarkets2.map((outcome, index) => (
              <Fragment key={index}>
                <Row className="m-0">
                  <Col
                    xs={6}
                    className={`ps-1 pe-1 text-center align-item-center justify-content-center mt-3 pt-0 ${
                      isDesktop ? 'text-white' : 'text-dark'
                    }`}>
                    {
                      getFootballOddTypeText(
                        market.id.toString(),
                        outcome[0].id.toString(),
                        outcome[0].name
                      ).split(' ')[0]
                    }
                  </Col>
                  <Col xs={6}>
                    <Row className="gy-2 gx-1">
                      {outcome.map((outcomes, index1) => (
                        <Col
                          key={market.id + ' ' + (outcome.id || matchId + '' + index1)}
                          xs={4}
                          className="">
                          <BetBox
                            providerId={matchId}
                            outcomeId={outcomes.id}
                            marketStringId={market.stringId}
                            sport={props.sportType}
                            path={props.path}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
                {groupedMarkets2.length !== index + 1 ? <hr></hr> : null}
              </Fragment>
            ))}
          </Container>
        );
      case EnumMarketName.ANYTIMEGOALSCORERWINNER_888:
      case EnumMarketName.NEXTGOALSCORERWINNER_891:
        return (
          <Container>
            <Row className="mb-1">
              <Col xs={6} className={`fs-7 ${isDesktop ? 'text-white' : 'text-dark'}`}>
                <div>&nbsp;</div>
                {ReverseName(market.outcomes[0].name.split('&')[0])}
              </Col>
              <Col xs={6}>
                <Row className="gy-2 gx-1">
                  {market.outcomes
                    .filter((outcome) => outcome.id !== '1897')
                    .map((outcome, index) => (
                      <Col
                        xs={oddsSize}
                        key={market.id + ' ' + (outcome.id || matchId + '' + index)}>
                        <BetBox
                          providerId={matchId}
                          outcomeId={outcome.id}
                          marketStringId={market.stringId}
                          sport={props.sportType}
                          path={props.path}
                        />
                      </Col>
                    ))}
                </Row>
              </Col>
            </Row>
          </Container>
        );
      default:
        return (
          <Container>
            <Row className="">
              {hideMarketLabel ? (
                market.id == EnumMarketName.TOTAL_18 && (
                  <Col xs={'auto'} className={` ${isDesktop ? 'mt-1-35' : 'mt-1'}`}>
                    <span
                      className={` ${
                        isDesktop ? 'text-yellow' : 'text-primary'
                      } bet-secondary me-0`}>
                      {OutcomeName(market.id, market.stringId, market.name)}
                    </span>
                  </Col>
                )
              ) : (
                <Col xs={6} className={`fs-7 ${isDesktop ? 'text-white' : 'text-dark'}`}>
                  <div>&nbsp;</div>
                  {OutcomeName(market.id, market.stringId, market.name)}
                </Col>
              )}
              <Col xs={hideMarketLabel ? 'auto' : 6}>
                <Row className="gy-2 gx-1 justify-content-end">
                  {sortedOutcomes?.map((outcome, index) => (
                    <Col
                      key={market.id + ' ' + (outcome.id || matchId + '' + index)}
                      xs={
                        isDesktop && hideMarketLabel && sortedOutcomes.length === 2 ? 6 : oddsSize
                      }
                      className="d-flex flex-column">
                      <BetBox
                        providerId={matchId}
                        outcomeId={outcome.id}
                        marketStringId={market.stringId}
                        sport={props.sportType}
                        path={props.path}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        );
    }
  }

  if (!market) {
    const emptyLabels = emptyMarketLabels(marketId);
    if (!emptyLabels || emptyLabels.length === 0) return <div></div>;
    return (
      <Row className="text-start">
        <Container>
          <Row className="align-items-center">
            <Col xs={'auto'}>
              <Row className="gy-2 gx-1">
                {emptyMarketLabels(marketId).map((marketLabel, index, arr) => (
                  <Col key={index} xs={12 / arr.length} className="">
                    <div className=" d-flex flex-column text-center">
                      {isDesktop ? '' : <span>{marketLabel}</span>}
                      {isDesktop ? (
                        <div
                          className="border-tertiary rounded justify-content-center text-dark px-1 d-inline-flex w-100 fw-bold mt-auto pt-1 pb-1 fs-8"
                          style={{ backgroundColor: '#696767' }}>
                          <img
                            className="icon-white"
                            width="17"
                            height="18"
                            src={`${AWS_BUCKET_URL}/Icons/lockicon.svg`}
                          />
                        </div>
                      ) : (
                        <div className="flex-center border-gray-300 rounded text-center px-1 py-1 w-100 fw-bold light-black-svg mt-auto fs-8">
                          <img
                            width="17"
                            height="18"
                            src={`${AWS_BUCKET_URL}/Icons/lockicon.svg`}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </Row>
    );
  }

  return <Row>{market && getMarkets()}</Row>;
}

export default BetTitleAndBox;
