import { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import { fetchNextLiveMatches } from 'stores/match';
import MatchCard from 'components/general/match_card.js';
import { eventsToGroupWithoutLeague } from 'toolkit/eventsToGroup.js';
// import Loading from 'components/general/loading';

const NextLiveMatches = ({ sport, selectedMarketType }) => {
  const { t } = useTranslation('translations');
  const dispatch = useDispatch();
  const selectedMatch = useSelector((state) => state.match.selectedMatch);
  const nextLiveMatches = useSelector((state) => state.match.nextLiveMatches);
  const nextLiveMatchesMeta = useSelector((state) => state.match.nextLiveMatchesMeta);
  const nextLiveEventsPage = useSelector((state) => state.match.nextLiveMatchesMeta.page);
  const liveEventsId = useSelector((state) => {
    const { liveMatches, events } = state.match;
    return liveMatches.map((c) => events[c]?.gameNumber);
  }, shallowEqual);
  const NextLiveEvents = useSelector(
    (state) => {
      const { nextLiveMatches, events } = state.match;
      return nextLiveMatches
        .map((c) => events[c])
        .filter((match) => {
          if (!match || !match.markets) return false;
          return (
            match.markets[0].outcomes[0]?.odds !== null ||
            match.markets[0].outcomes[1]?.odds !== null ||
            match.markets[0].outcomes[2]?.odds !== null
          );
        });
    },
    (a, b) => a.length === b.length
  );
  const hideShowMoreButton =
    nextLiveMatchesMeta &&
    (nextLiveMatchesMeta.page + 1) * nextLiveMatchesMeta.pageSize > nextLiveMatchesMeta.totalCount;
  const selectedLocale = useSelector((state) => state.match.selectedLocale);
  const locales = useSelector((state) => state.match.locales);
  const [loading, setLoading] = useState(false);
  const [showMoreActive, setShowMoreActive] = useState(false);
  const [sports, setSports] = useState({});

  useEffect(() => {
    const groups = eventsToGroupWithoutLeague(NextLiveEvents);
    setSports(groups);
  }, [NextLiveEvents]);

  const getData = async () => {
    try {
      if (sport === '') return;
      setLoading(true);
      await dispatch(fetchNextLiveMatches({ sport })).unwrap();
      if (nextLiveMatches.length === 0) {
        // getData({ sport, page: nextLiveEventsPage + 1 });
      }
    } catch (error) {
      // error
    }
    setLoading(false);
  };

  const handleShowMoreClick = async () => {
    try {
      setShowMoreActive(true);
      await dispatch(fetchNextLiveMatches({ sport, page: nextLiveEventsPage + 1 })).unwrap();
    } catch (error) {
      // error
    }
    setShowMoreActive(false);
  };

  useEffect(() => {
    getData();
  }, [sport]);

  useEffect(() => {
    if (loading) return;

    if (selectedMatch) {
      const selectedMatchCard = document.querySelector(`a[href*="${selectedMatch}"]`);
      if (selectedMatchCard) {
        selectedMatchCard.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [loading]);

  // if (loading) return <Loading message={t('search.loadingMatches')} />;

  function getBets() {
    const matchCards = [];

    sport !== '' &&
      sports[sport] !== undefined &&
      sports[sport].length > 0 &&
      sports[sport].map((leagueGroup, index) => {
        matchCards.push(
          <Fragment
            key={
              'leaguenamesfragment' +
              leagueGroup.category +
              leagueGroup.league +
              leagueGroup.matches[0].gameNumber +
              index
            }>
            <div
              className="text-start pb-2 fw-bold pt-1 fs-7"
              key={
                'leaguenames' +
                leagueGroup.category +
                leagueGroup.league +
                leagueGroup.matches[0].gameNumber
              }>
              {leagueGroup.category + ' / ' + leagueGroup.league}
            </div>
            {leagueGroup.matches
              .filter((x) => !liveEventsId.includes(x))
              .map((match, index) => (
                <MatchCard
                  key={match.providerId}
                  providerId={match.providerId}
                  marketType={selectedMarketType === 7 ? 1 : selectedMarketType}
                  index={index}
                  sport={sport}
                  path={location.pathname}
                  locales={locales}
                  selectedLocale={selectedLocale}
                />
              ))}
          </Fragment>
        );
      });
    return matchCards;
  }

  return (
    <div>
      {nextLiveMatches.length > 0 && <hr />}
      {getBets()}
      {!hideShowMoreButton && (
        <div className="d-flex justify-content-center mb-2 mt-3">
          <button
            className="btn btn-primary"
            onClick={handleShowMoreClick}
            disabled={showMoreActive}>
            {showMoreActive ? (
              <Spinner size="sm" animation="border" role="status" variant={'light'}>
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              '+'
            )}
            <span className="ms-1">{t('pages.desktop.show_more')}</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default NextLiveMatches;
