export const EnumMarketName = {
  WINNER_1: 1,
  WINFINAL: 3,
  WIN3RDFINAL: 4,
  WINNIGMETHOD_5: 5,
  ROM_7: 7,
  NEXTGOAL_8: 8,
  DOUBLECHANCE_10: 10,
  HANDICAP_14: 14,
  HANDICAP_16: 16,
  TOTAL_18: 18,
  HOMETOTAL_19: 19,
  AWAYTOTAL_20: 20,
  EXACTGOALS_21: 21,
  HOMEEXACTGOALS_23: 23,
  AWAYEXACTGOALS_24: 24,
  GOALRANGE_25: 25,
  ODDEVEN_26: 26,
  BOTHTEAMSTOSCORE_29: 29,
  WHICTEAMTOSCORE_30: 30,
  HOMEWINTONIL_33: 33,
  AWAYWINTONIL_34: 34,
  WINNERBOTHTEAMSTOSCORE_35: 35,
  OVERUNDERANDBOTHTEAMSTOSCORE_36: 36,
  WINNERANDOVERUNDER_37: 37,
  NEXTGOALSCORER_38: 38,
  LASTGOALSCORER_39: 39,
  ANYTIMEGOALSCORER_40: 40,
  CORRECTSCORE_41: 41,
  HALFTIMEFULLTIME_47: 47,
  HOMETOWINBOTHHALVES_48: 48,
  AWAYTOWINBOTHHALVES_49: 49,
  HOMETOWINEITHERHALF_50: 50,
  AWAYTOWINEITHERHALF_51: 51,
  HIGHSCORINGHALF_52: 52,
  HOMEHIGHSCORINGHALF_53: 53,
  AWAYHIGHSCORINGHALF_54: 54,
  FISTORSECONDHALDBOTHTEAMSTOSCORE_55: 55,
  HOMESCOREINBOTHHALVES_56: 56,
  AWAYSCOREINBOTHHALVES_57: 57,
  BOTHHALVESOVER_58: 58,
  HOMEOVERUNDER_59: 59,
  FIRSTHALFWINNER_60: 60,
  FIRSTHALFROM_61: 61,
  FIRSTHALFNEXTGOAL_62: 62,
  FIRSTHALFDOUBLECHANCE_63: 63,
  FIRSTHALFHANDICAP_65: 65,
  FIRSTHALFHANDICAP_66: 66,
  FIRSTHALFOVERUNDER_68: 68,
  FIRSTHALFHOMEOVERUNDER_69: 69,
  FIRSTHALFAWAYOVERUNDER_70: 70,
  FIRSTHALFEXACTGOALS_71: 71,
  FIRSTHALFODDEVEN_74: 74,
  FIRSTHALFBOTHTEAMSTOSCORE_75: 75,
  FIRSTHALFWINNERANDBOTHTEAMSTOSCORE_78: 78,
  FIRSTHALFWINNERANDOVERUNDER_79: 79,
  SECONDHALFWINNER_83: 83,
  SECONDHALFNEXTGOAL_84: 84,
  SECONDHALFDOUBLECHANCE_85: 85,
  SECONDHALFDRAWNOBET_86: 86,
  SECONDHALFOVERUNDER_90: 90,
  SECONDHALFHOMEOVERUNDER_91: 91,
  SECONDHALFAWAYOVERUNDER_92: 92,
  SECONDHALFODDEVEN_93: 94,
  SECONDHALFBOTHTEAMSTOSCORE_95: 95,

  NEXTGOALTIME_100: 100,

  OVERTIMEGOAL_112: 112,
  OVERTIME_WINNER_113: 113,
  OVERTIME_RESTOFMATCH_114: 114,
  OVERTIMENEXTGOAL_115: 115,
  OVERTIME_TOTAL_116: 116,

  WILLTHEREBEAPENALTY_122: 112,
  WILLTHEREBEAPENALTY_WINNER_123: 123,

  CORNERHANDICAP_162: 162,
  OVERUNDERCORNERS_166: 166,
  WINNER_186: 186,
  GAMEHANDICAP_187: 187,
  SETHANDICAP_188: 188,
  TOTALGAMES_189: 189,

  HOMETOWINSET_192: 192,
  AWAYTOWINSET_193: 193,
  EXACTSETS_196: 196,
  CORRECTSCORE_199: 199,
  SETWINNER_202: 202,
  TOTALGAMESSET_204: 204,
  SETCORRECTSCORE_207: 207,
  WINNER_219: 219,
  HANDICAP_223: 223,
  TOTAL_225: 225,
  HOMEOVERUNDER_227: 227,
  AWAYOVERUNDER_228: 228,
  HIGHESTSCORINGQUARTER_234: 234,
  QUARTERWINNER_235: 235,
  QUARTEROVERUNDER_236: 236,
  POINTHANDICAP_237: 237,
  TOTALPOINT_238: 238,
  HANDICAP_256: 256,
  TOTAL_258: 258,
  WINNERANDOVERUNDER_292: 292,
  QUARTERHANDICAP_303: 303,
  WINNERANDOVERUNDER_1055: 1055,
  CORRECTSCORE_329: 329,
  WILLTHEREBEASUPEROVER_341: 341,
  FRAMEHANDICAP_493: 493,
  TOTALFRAMES_494: 494,
  FRAMEWINNER_499: 499,

  DOUBLECHANCEFIRSTHALFBOTHTEAMSCORE_540: 540,
  FIRSTHALFDOUBLECHANCEBOTHTEAMSCORE_542: 542,
  DOUBLECHANCEFIRSTHALFBOTHTEAMSCORE_546: 546,
  DOUBLECHANCEOVERUNDER_547: 547,

  MULTISCORES_551: 551,
  FIRSTHALFMULTIGOALS_552: 552,
  INNINGSTOTAL_605: 605,
  HOMEOVERUNDER_756: 756,
  AWAYOVERUNDER_757: 757,
  HALFTIMEFULLTIMETOTAL_818: 818,
  HALFTIMEFULLTIMEFIRSTHALFTOTAL_819: 819,
  HALFTIMEFULLTIMEEXACTGOALS_820: 820,
  ANYTIMEGOALSCORERWINNER_888: 888,
  NEXTGOALSCORERWINNER_891: 891,
  NEXTGOALSCORER_892: 892,
  NEXTSCORINGTYPE_896: 896,
  OVERUNDERANDSETWINNER_1056: 1056
};

export const EnumMatchStatusName = {
  UNKNOWN: -1,
  NOT_STARTED: 0,
  FIRST_PERIOD: 1,
  SECOND_PERIOD: 2,
  THIRD_PERIOD: 3,
  FOURTH_PERIOD: 4,
  FIFTH_PERIOD: 5,
  FIRST_HALF: 6,
  SECOND_HALF: 7,
  FIRST_SET: 8,
  SECOND_SET: 9,
  THIRD_SET: 10,
  FOURTH_SET: 11,
  FIFTH_SET: 12,
  FIRST_QUARTER: 13,
  SECOND_QUARTER: 14,
  THIRD_QUARTER: 15,
  FOURTH_QUARTER: 16,
  GOLDEN_SET: 17,
  GOLDEN_RAID: 18,
  COIN_TOSS: 19,
  STARTED: 20,
  IN_PROGRESS: 21,
  ABOUT_TO_START: 22,
  BREAK: 30,
  HALFTIME: 31,
  AWAITING_EXTRA_TIME: 32,
  EXTRA_TIME_HALFTIME: 33,
  AWAITING_PENALTIES: 34,
  AWAITING_PENALTIES_ID_35: 35,
  AWAITING_PENALTIES_ID_36: 36,
  AWAITING_GOLDEN_SET: 37,
  AWAITING_GOLDEN_RAID: 38,
  GOLDEN_RAID_ID_39: 39,
  OVERTIME: 40,
  FIRST_EXTRA: 41,
  SECOND_EXTRA: 42,
  PENALTIES: 50,
  PENALTIES_ID_51: 51,
  PENALTIES_ID_52: 52,
  POSTPONED: 60,
  START_DELAYED: 61,
  CANCELLED: 70,
  GAME_1: 71,
  GAME_2: 72,
  GAME_3: 73,
  GAME_4: 74,
  GAME_5: 75,
  GAME_6: 76,
  GAME_7: 77,
  INTERRUPTED: 80,
  SUSPENDED: 81,
  ABANDONED: 90,
  WALKOVER: 91,
  RETIRED: 92,
  WALKOVER_PLAYER_1_WON: 93,
  WALKOVER_PLAYER_2_WON: 94,
  PLAYER_1_RETIRED_PLAYER_2_WON: 95,
  PLAYER_2_RETIRED_PLAYER_1_WON: 96,
  PLAYER_1_DEFAULTED_PLAYER_2_WON: 97,
  PLAYER_2_DEFAULTED_PLAYER_1_WON: 98,
  ONLY_RESULT: 99,
  ENDED: 100,
  AET: 110,
  AOT: 111,
  AP: 120,
  AFTER_GOLDEN_SET: 130,
  AFTER_GOLDEN_RAID: 140,
  FIRST_MAP: 141,
  SECOND_MAP: 142,
  THIRD_MAP: 143,
  FOURTH_MAP: 144,
  FIFTH_MAP: 145,
  SIXTH_MAP: 146,
  SEVENTH_MAP: 147,
  FIRST_GAME: 151,
  SECOND_GAME: 152,
  THIRD_GAME: 153,
  FOURTH_GAME: 154,
  FIFTH_GAME: 155,
  FIRST_END: 161,
  SECOND_END: 162,
  THIRD_END: 163,
  FOURTH_END: 164,
  FIFTH_END: 165,
  SIXTH_END: 166,
  SEVENTH_END: 167,
  EIGHTH_END: 168,
  NINTH_END: 169,
  TENTH_END: 170,
  EXTRA_END: 171,
  FIRST_BREAK: 301,
  SECOND_BREAK: 302,
  THIRD_BREAK: 303,
  FOURTH_BREAK: 304,
  FIFTH_BREAK: 305,
  SIXTH_BREAK: 306,
  FIRST_INNING_TOP: 401,
  FIRST_INNING_BOTTOM: 402,
  SECOND_INNING_TOP: 403,
  SECOND_INNING_BOTTOM: 404,
  THIRD_INNING_TOP: 405,
  THIRD_INNING_BOTTOM: 406,
  FOURTH_INNING_TOP: 407,
  FOURTH_INNING_BOTTOM: 408,
  FIFTH_INNING_TOP: 409,
  FIFTH_INNING_BOTTOM: 410,
  SIXTH_INNING_TOP: 411,
  SIXTH_INNING_BOTTOM: 412,
  SEVENTH_INNING_TOP: 413,
  SEVENTH_INNING_BOTTOM: 414,
  EIGHTH_INNING_TOP: 415,
  EIGHTH_INNING_BOTTOM: 416,
  NINTH_INNING_TOP: 417,
  NINTH_INNING_BOTTOM: 418,
  EXTRA_INNING_TOP: 419,
  EXTRA_INNING_BOTTOM: 420,
  BREAK_TOP_1_BOTTOM_1: 421,
  BREAK_TOP_2_BOTTOM_1: 422,
  BREAK_TOP_2_BOTTOM_2: 423,
  BREAK_TOP_3_BOTTOM_2: 424,
  BREAK_TOP_3_BOTTOM_3: 425,
  BREAK_TOP_4_BOTTOM_3: 426,
  BREAK_TOP_4_BOTTOM_4: 427,
  BREAK_TOP_5_BOTTOM_4: 428,
  BREAK_TOP_5_BOTTOM_5: 429,
  BREAK_TOP_6_BOTTOM_5: 430,
  BREAK_TOP_6_BOTTOM_6: 431,
  BREAK_TOP_7_BOTTOM_6: 432,
  BREAK_TOP_7_BOTTOM_7: 433,
  BREAK_TOP_8_BOTTOM_7: 434,
  BREAK_TOP_8_BOTTOM_8: 435,
  BREAK_TOP_9_BOTTOM_8: 436,
  BREAK_TOP_9_BOTTOM_9: 437,
  BREAK_TOP_EI_BOTTOM_9: 438,
  BREAK_TOP_EI_BOTTOM_EI: 439,
  SUDDEN_DEATH: 440,
  SIXTH_SET: 441,
  SEVENTH_SET: 442,
  AWAITING_SUDDEN_DEATH: 443,
  AFTER_SUDDEN_DEATH: 444,
  BREAK_ID_445: 445,
  FIRST_INNINGS_HOME_TEAM: 501,
  FIRST_INNINGS_AWAY_TEAM: 502,
  SECOND_INNINGS_HOME_TEAM: 503,
  SECOND_INNINGS_AWAY_TEAM: 504,
  AWAITING_SUPER_OVER: 505,
  SUPER_OVER_HOME_TEAM: 506,
  SUPER_OVER_AWAY_TEAM: 507,
  AFTER_SUPER_OVER: 508,
  INNINGS_BREAK: 509,
  SUPER_OVER_BREAK: 510,
  LUNCH_BREAK: 511,
  TEA_BREAK: 512,
  STUMPS: 513,
  EIGHTH_SET: 514,
  NINTH_SET: 515,
  TENTH_SET: 516,
  ELEVENTH_SET: 517,
  TWELFTH_SET: 518,
  THIRTEENTH_SET: 519,
  THIRD_INNINGS_HOME_TEAM: 520,
  THIRD_INNINGS_AWAY_TEAM: 521,
  FOURTH_INNINGS_HOME_TEAM: 522,
  FOURTH_INNINGS_AWAY_TEAM: 523,
  DINNER_BREAK: 524,
  DRINKS: 525,
  SUPER_OVER: 526,
  FIRST_INNING: 531,
  SECOND_INNING: 532,
  THIRD_INNING: 533,
  FOURTH_INNING: 534,
  FIFTH_INNING: 535,
  SIXTH_INNING: 536,
  SEVENTH_INNING: 537,
  EIGHTH_INNING: 538,
  NINTH_INNING: 539,
  FIRST_ROUND: 540,
  SECOND_ROUND: 541,
  THIRD_ROUND: 542,
  FOURTH_ROUND: 543,
  FIFTH_ROUND: 544,
  SIXTH_ROUND: 545,
  SEVENTH_ROUND: 546,
  BREAK_TOP_EI_BOTTOM_3: 547,
  BREAK_TOP_EI_BOTTOM_7: 548
};

export const EnumEventStatus = {
  NONE: -1,
  NOTSTARTED: 0,
  RUNNING: 1,
  FINISHED: 2,
  ABANDODED: 3,
  CANCELLED: 4,
  CLOSED: 5,
  DELAYED: 6,
  INTERRUPTED: 7,
  POSTPONED: 8,
  SUSPENDED: 9,
  ABOUTTOSTART: 10,
  OTHER: 99
};

export const EnumBetslipStatus = {
  OPEN: 1,
  WON: 2,
  LOST: 3,
  CASHOUT: 4,
  REJECTED: 5,
  STALE: 6,
  CANCELED: 7
};

export const EnumBetSlipType = {
  SINGLE: 1,
  MULTIPLE: 2,
  SYSTEM: 3
};
export const EnumMarketStatus = {
  INITIALIZING: 0,
  CANCELLED: 1,
  SETTLED: 2,
  HANDEOVER: 3,
  SUSPENDED: 4,
  INACTİVE: 5,
  ACTIVE: 6
};

export const EBetGroupName = {
  TOP_BETS: 'topBets',
  Over_Under: 'overUnder',
  FIRST_HALF_TIME: '1stHalfTime',
  SECOND_HALF_TIME: '2ndHalfTime',
  HANDICAP: 'handicap',
  CORNER: 'corner',
  GOALS: 'goals',
  PLAYER: 'player',
  DOUBLE_BETS: 'doubleBets',
  TRIPLE_BETS: 'tripleBets',
  SPECIAL_BETS: 'specialBets'
};
