import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Navbar, Offcanvas, Button } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PersonCircle } from 'react-bootstrap-icons';
import Profile from 'views/profile/profile';
import { AWS_BUCKET_URL } from '../toolkit/constants';
import Desktop from 'components/breakpoints/Desktop';
import Default from 'components/breakpoints/Default';
import SearchOffCanvas from './_components/SearchOffCanvas';
import Language from './_components/Language';
import { getLanguageCode } from 'toolkit/utils';
import { useMediaQuery } from 'react-responsive';
import { fetchBalance } from 'stores/auth';

const Header = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const userBalance = useSelector((state) => state.auth.balance);
  const { t } = useTranslation('translations', { useSuspense: false });
  const location = useLocation();
  const [showSearchOffcanvas, setShowSearchOffcanvas] = useState(false);
  const [dateState, setDateState] = useState(new Date());
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleShowSearchDesktopOffCanvas = () => {
    setShowSearchOffcanvas(!showSearchOffcanvas);
  };

  const isDesktop = useMediaQuery({
    query: '(min-width: 992px)'
  });

  useEffect(() => {
    setInterval(() => {
      setDateState(new Date());
    }, 30000);
  }, []);

  useEffect(() => {
    if (user) dispatch(fetchBalance());
  }, []);

  let userMenu;
  if (user) {
    userMenu = (
      <>
        <Desktop>
          <Button variant="link text-white col-auto ms-3" onClick={handleShow}>
            <PersonCircle size="20" />
            <div className="fs-8">
              {(userBalance ?? 0).toLocaleString('de-DE', {
                maximumFractionDigits: 2
              }) + ' EUR'}
            </div>
          </Button>
        </Desktop>
        <Default>
          <Button variant="link text-white pb-3" onClick={handleShow}>
            <PersonCircle size="20" />
          </Button>
        </Default>
        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="px-0 pt-0 pb-3">
            <Profile onClose={handleClose} />
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
  //mobile için header className="bg-danger" olacak
  return (
    <header className={`${isDesktop ? 'bg-header' : 'header-bg-color'}`}>
      <Desktop>
        <Container fluid className="h-100">
          <div className="d-flex gap-2 align-items-center py-2">
            <Language isDesktop={true} />
            <div className="vr text-white"></div>
            <span className="text-white fs-6">
              {dateState.toLocaleString(getLanguageCode(), {
                hour: 'numeric',
                minute: 'numeric',
                hour12: false
              })}
            </span>
          </div>
          <div className="d-flex align-items-center">
            {user && (
              <NavLink to="/sport" className={'me-3'}>
                <img
                  width="185"
                  height="45"
                  className={'ms-n2'}
                  src={`${AWS_BUCKET_URL}/Icons/game90.betlogobeyaz.png`}
                />
              </NavLink>
            )}
            <div className="d-flex gap-3 align-items-center fs-6">
              <div className="header-slot">
                <NavLink className={'text-white fw-semibold'} to={'/sport'}>
                  {t('pages.desktop.header.sports')}
                </NavLink>
              </div>
              <div className="header-slot">
                <NavLink className={'text-white fw-semibold'} to={'/live'}>
                  {t('pages.desktop.header.live')}
                </NavLink>
              </div>
              {user ? (
                <div className="header-slot">
                  <NavLink className={'text-white fw-semibold'} to={'/slot'}>
                    {t('pages.desktop.header.slot')}
                  </NavLink>
                </div>
              ) : null}
              {user ? (
                <div className="header-slot">
                  <NavLink className={'text-white fw-semibold'} to={'/casino'}>
                    {t('pages.desktop.header.casino')}
                  </NavLink>
                </div>
              ) : null}
              <div className="header-slot">
                <NavLink className={'text-white fw-semibold'} to={'/results'}>
                  {t('pages.desktop.header.results')}
                </NavLink>
              </div>
              {user ? (
                <div className="header-slot">
                  <NavLink className={'text-white fw-semibold'} to={'/mybets'}>
                    {t('mybets')}
                  </NavLink>
                </div>
              ) : null}
            </div>
            <button className="btn p-0 ms-auto" onClick={handleShowSearchDesktopOffCanvas}>
              <img
                className="svg-size-20"
                style={{
                  filter: 'invert(1)'
                }}
                src={`${AWS_BUCKET_URL}/Icons/search-icon.svg`}
              />
            </button>
            {user ? (
              userMenu
            ) : (
              <NavLink className={'link-light fw-semibold ms-3 fs-6'} to={'/login'}>
                {t('loginTitle')}
              </NavLink>
            )}
          </div>
        </Container>
        <SearchOffCanvas
          show={showSearchOffcanvas}
          handleClose={handleShowSearchDesktopOffCanvas}
        />
      </Desktop>
      <Default>
        <Navbar>
          <Container fluid>
            {location.pathname !== '/slot-game' &&
              location.pathname !== '/casino' &&
              location.pathname !== '/slot' && (
                <NavLink to="/search">
                  <img
                    className="text-white fs-5 mb-2 svg-size-20 cursor-pointer"
                    style={{
                      filter: 'invert(1)'
                    }}
                    src={`${AWS_BUCKET_URL}/Icons/search-icon.svg`}
                  />
                </NavLink>
              )}

            {user && (
              <Navbar.Brand>
                <NavLink to="/">
                  <img
                    width="185"
                    height="45"
                    className="cursor-pointer ps-5 pb-2"
                    src={`${AWS_BUCKET_URL}/Icons/game90.betlogobeyaz.png`}
                  />
                </NavLink>
              </Navbar.Brand>
            )}

            {location.pathname === '/slot-game' && (
              <NavLink to="/slot">
                <img
                  className="text-white fs-5 w-100 icon-color cursor-pointer"
                  src={`${AWS_BUCKET_URL}/Icons/back-icon.svg`}
                />
              </NavLink>
            )}

            {(location.pathname === '/casino' || location.pathname === '/slot') && (
              <NavLink
                to={{
                  pathname: location.pathname === '/slot' ? '/' : '/casino',
                  search: location.pathname === '/slot' ? '' : 'home'
                }}>
                <img
                  className="text-white fs-5 w-100 icon-color cursor-pointer"
                  src={`${AWS_BUCKET_URL}/Icons/back-icon.svg`}
                />
              </NavLink>
            )}
            {/* <div className="ms-auto">
              <Language />
            </div> */}
            {userMenu}
          </Container>
        </Navbar>
      </Default>
    </header>
  );
};

export default Header;
