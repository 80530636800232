import { useState, useEffect, useCallback } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import {
  fetchEventsByCategory,
  fetchEventsByCategoryWithHours,
  fetchEventsBySport,
  setSelectedLeague
} from 'stores/match';
import { AWS_BUCKET_URL } from 'toolkit/constants';
import { calculateTimeParameter, isToday, sportTypeToSportId } from 'toolkit/utils.js';
import TitleHistoryBack from 'components/general/TitleHistoryBack.js';
import MatchCard from 'components/general/match_card.js';
import SportDetailItem from 'components/SportDetailItem.js';
import Loading from 'components/general/loading';

export default function SportDetailsMatches() {
  const dispatch = useDispatch();
  const { t } = useTranslation('translations');
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const { sportType } = useParams();
  const [searchParams] = useSearchParams();
  const categoryId = searchParams.get('category');
  const leagueId = searchParams.get('league');
  const time = searchParams.get('time');
  const [loading, setLoading] = useState(false);
  const [fetchedEvents, setFetchedEvents] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const selectedLeague = useSelector((state) => state.match.selectedLeague);
  const [selectedMarketType, setSelectedMarketType] = useState(1);
  const selectLeague = useCallback((id) => {
    dispatch(setSelectedLeague(id));
  });

  const getData = async () => {
    try {
      setLoading(true);
      if (categoryId === 'all') {
        const { betEvents } = await dispatch(
          fetchEventsBySport({
            sport: sportTypeToSportId(sportType),
            dateFilter: calculateTimeParameter(time)
          })
        ).unwrap();
        setFetchedEvents(
          Object.values(betEvents)
            .map((c) => Object.values(c))
            .flat()
            .flat()
        );
      } else if (time) {
        const { betEvents } = await dispatch(
          fetchEventsByCategory({ category: categoryId, dateFilter: calculateTimeParameter(time) })
        ).unwrap();
        setFetchedEvents(Object.values(betEvents).flat());
      } else {
        const { betEvents } = await dispatch(
          fetchEventsByCategoryWithHours({ Category: categoryId, Hours: 0 })
        ).unwrap();
        setFetchedEvents(Object.values(betEvents).flat());
      }
    } catch (error) {
      // error
    }
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  useEffect(() => {
    if (!loading && fetchedEvents.length > 0) {
      let tempLeagueArr = [];
      if (fetchedEvents.find((e) => isToday(new Date(e.gameDate)))) {
        tempLeagueArr.push({
          leagueId: 'Today',
          leagueName: t(`today`),
          events: []
        });
      }
      fetchedEvents.forEach((event) => {
        if (isToday(new Date(event.gameDate))) {
          const todayIndex = tempLeagueArr.findIndex((l) => l.leagueId === 'Today');
          tempLeagueArr[todayIndex].events.push(event);
        }
        const { leagueId } = event;
        const leagueIndex = tempLeagueArr.findIndex((l) => l.leagueId === leagueId);
        if (leagueIndex === -1) {
          tempLeagueArr.push({
            leagueId,
            leagueName: event.league,
            events: [event]
          });
        } else {
          tempLeagueArr[leagueIndex].events.push(event);
        }
      }, []);
      tempLeagueArr = tempLeagueArr.sort((a, b) => {
        if (a.leagueId === 'Today' || b.leagueId === 'Today') return 1;
        return Number(b.leagueId.split(':')[2]) < Number(a.leagueId.split(':')[2]) ? 1 : -1;
      });
      if (tempLeagueArr.find((l) => l.leagueId === leagueId)) {
        selectLeague(leagueId);
      } else {
        selectLeague(tempLeagueArr[0].leagueId);
      }
      setLeagues(tempLeagueArr);
    }
  }, [fetchedEvents]);

  function getLeagues() {
    if (leagues.length > 0) {
      return leagues.map(({ leagueId, events, leagueName }) => (
        <SportDetailItem
          key={leagueId}
          active={selectedLeague === leagueId}
          onClick={() => selectLeague(leagueId)}
          text={leagueName}
          count={leagueId === 'Today' ? undefined : events.length}
          icon={
            <img
              className="icon-color"
              width="25"
              height="25"
              src={`${AWS_BUCKET_URL}/Icons/star${
                selectedLeague === leagueId ? 'fill' : 'empty'
              }.svg`}
            />
          }
        />
      ));
    }
    return null;
  }

  function getBetType() {
    const betTypes = [
      {
        label: 'Winner',
        id: 1
      },
      {
        label: 'O/U',
        id: 18
      },
      {
        label: 'HC',
        id: 14
      },
      {
        label: 'NG',
        id: 8
      },
      {
        label: 'BS',
        id: 29
      },
      {
        label: 'DC',
        id: 10
      }
    ];
    return (
      <div className="tip-types shadow">
        {betTypes.map((betType) => (
          <div className="d-inline-block w-25 text-align-center fw-bold" key={betType.id}>
            <button
              className={`btn btn-light btn-sm w-100 border fs-8 ${
                selectedMarketType === betType.id ? 'match-date-bg-color fw-bold text-white' : ''
              }`}
              onClick={() => setSelectedMarketType(betType.id)}>
              {betType.label}
            </button>
          </div>
        ))}
      </div>
    );
  }

  function getBets() {
    const matchCardList = [];
    const leagueArray = [];
    const selectedLeagueObj = leagues.find((l) => l.leagueId === selectedLeague);
    if (!selectedLeagueObj) return;
    const { events } = selectedLeagueObj;
    if (events.length > 0) {
      events
        .sort((a, b) => new Date(a.gameDate).getTime() - new Date(b.gameDate).getTime())
        .filter((a) => a.markets && a.league && a.gameNumber)
        .forEach(function (bet) {
          const findedMarket = bet.markets.find((market) => market.id === selectedMarketType);
          const lock =
            !findedMarket ||
            !findedMarket.outcomes ||
            findedMarket.outcomes.findIndex((element) => element.odds !== null) === -1;
          if (!leagueArray.includes(bet.league)) {
            leagueArray.push(bet.league);
            matchCardList.push(
              <div className="text-start pb-1 fw-bold pt-1 fs-7" key={'league-' + bet.league}>
                {bet.league}
              </div>
            );
          }
          return matchCardList.push(
            <MatchCard
              key={bet.gameNumber}
              marketType={selectedMarketType}
              providerId={bet.providerId}
              lockIfNoMarket={lock}
            />
          );
        });
    }
    return matchCardList;
  }

  function titles() {
    const titleList = [];
    titleList.push(
      <div className="float-start w-100 mb-1" key={sportType}>
        <span>Sport | {sportType}</span>
      </div>
    );
    return titleList;
  }

  function categoryName() {
    if (categoryId === 'all') return t('allcategories');
    const selectedLeagueObj = leagues.find((l) => l.leagueId === selectedLeague);
    if (!selectedLeagueObj) return '';
    return selectedLeagueObj.events[0].category;
  }

  const handleScroll = () => {
    const container = window;
    const stickyEl = document.querySelector('.tip-types');
    if (!stickyEl) return;
    const scrollTop = container.scrollY;
    const direction = scrollTop < lastScrollPosition ? 'up' : 'down';
    setLastScrollPosition(scrollTop);

    if (scrollTop > 110) {
      stickyEl.classList.add('fixed');
      if (direction === 'down') {
        if (scrollTop > 200) {
          stickyEl.classList.remove('fixed');
        }
      } else {
        if (scrollTop > 200) {
          stickyEl.classList.add('fixed');
        }
      }
    } else {
      stickyEl.classList.remove('fixed');
    }
  };

  if (window) {
    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);
  }

  if (loading) return <Loading message={t('search.loadingMatches')} />;

  return (
    <>
      <div className="container scrollable-y">
        <div className="overflow-auto d-flex py-2 gap-2">{getLeagues()}</div>
        <TitleHistoryBack title={titles()} subTitle={categoryName()} />
        {sportType === 'Football' && (
          <Row className="mt-n2">
            <Col className="tip-types-container">{getBetType()}</Col>
          </Row>
        )}
        <div className="">{getBets()}</div>
      </div>
    </>
  );
}
