import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, Row, Modal, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as _ from 'lodash';
import { combination } from 'js-combinatorics';
import { Check2Square } from 'react-bootstrap-icons';
import { BetSlipRow } from './bet_slip_row';
import Numpad from './numpad';
import './forza-betslip.scss';
import FetchButton from 'components/general/FetchButton';
import { AWS_BUCKET_URL } from 'toolkit/constants';
import useInterval from 'toolkit/useHooks/useInterval';
import { ConvertDisplayNumber } from 'toolkit/NumberUtil';
import { currencyFormatter, parsePlaceBetResponseMsg } from 'toolkit/utils';
import { EnumBetSlipType } from 'toolkit/Enums';
import { getShopId } from 'stores/auth/services';
import { fetchBalance } from 'stores/auth';
import {
  removeAllBetSlip,
  setBetSlipCombination,
  setBetSlipSubmitting,
  setBetSlipSystem,
  setResponseData,
  setResultModalShow,
  // setShowSuccessModal,
  setTotal
} from 'stores/betSlip';
import { postPlaceBetReq } from 'stores/betSlip/services';
import useOddTransformer from '../../toolkit/useHooks/useOddsTransformer.js';
import { checkIfLiveEvent } from '../../toolkit/utils';
import { useCategoryLimitValidation } from 'toolkit/useHooks/useCategoryLimitValidation';

export default function BetSlipList(props) {
  const dispatch = useDispatch();
  const { user, balance, refundBonusBalance } = useSelector((state) => state.auth);
  const betSlip = useSelector((state) => state.betSlip);
  const isBetSlipSubmitting = useSelector((state) => state.betSlip.isBetSlipSubmitting);
  const isBetSlipSubmittingRef = useRef(isBetSlipSubmitting);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { selectedLocale, locales } = useSelector((state) => state.match);
  const betSlipEvents = useSelector(
    (state) => {
      const eventsObj = {};
      betSlip.bets.forEach((bet) => {
        const event = state.match.events[bet.providerId];
        if (event) {
          eventsObj[bet.providerId] = event;
        }
      });
      return eventsObj;
      // betSlip.bets.reduce(
      //   (betsObj, bet) => ({
      //     ...betsObj,
      //     [bet.providerId]: state.match.events[bet.providerId]
      //   }),
      //   {}
      // )
    },
    (prev, next) => _.isEqual(prev, next)
  );
  const { t } = useTranslation('translations', { useSuspense: false });
  const navigate = useNavigate();
  const [activeSegment, setActiveSegment] = useState(betSlip.system ? 2 : 1);
  const [combinationRelatedBetCount, setCombinationRelatedBetCount] = useState(0);
  const [stakeExpanded, setStakeExpanded] = useState(false);
  const [numpadShow, setNumpadShow] = useState(false);
  const [combinationsExpanded, setCombinationsExpanded] = useState(false);
  const [combinationsText, setCombinationsText] = useState([]);
  const [numberOfBets, setNumberOfBets] = useState(0);
  const [stakePerBet, setStakePerBet] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [maxProfit, setMaxProfit] = useState(0);
  const stakeInput = useRef(null);
  const betSlipTop = useRef(null);
  const betSlipCenter = useRef(null);
  const betSlipBottom = useRef(null);
  const [showConfirmationRow, setShowConfirmationRow] = useState(false);
  const [timer, setTimer] = useState(20);
  const _currencyFormatter = currencyFormatter(betSlip.currencyCode, 2);
  const [input, setInput] = useState(parseFloat(betSlip.total).toFixed(2));
  const oddsTransformer = useOddTransformer();
  const { validateBetSlipCategoryLimit } = useCategoryLimitValidation();
  const [useBonusBalance, setUseBonusBalance] = useState(
    refundBonusBalance > 0 && balance == 0 ? true : false
  );

  useEffect(() => {
    isBetSlipSubmittingRef.current = isBetSlipSubmitting;
  }, [isBetSlipSubmitting]);

  const handleRestoreClick = () => {
    setShowSuccessModal(false);
    setTimer(20);
    setShowConfirmationRow(false);
  };

  useInterval(
    () => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    },
    showSuccessModal ? 1000 : null
  );

  useEffect(() => {
    if (timer === 0) {
      dispatch(setResultModalShow(true));
      dispatch(removeAllBetSlip());
    }
  }, [timer]);

  const goToPreviousPath = () => {
    navigate(-1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (user) {
      dispatch(fetchBalance());
    }

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden' && isBetSlipSubmittingRef.current) {
        handlePageExit();
      }
    };

    const handleBeforeUnload = (event) => {
      if (isBetSlipSubmittingRef.current) {
        event.preventDefault();
        event.returnValue = '';
        handlePageExit();
      }
    };

    const handlePageExit = () => {
      if (isBetSlipSubmittingRef.current) {
        dispatch(setBetSlipSubmitting(false));
        dispatch(setResultModalShow(false));
        setShowSuccessModal(false);
        dispatch(removeAllBetSlip());
      }
    };

    const handlePageHide = () => {
      if (isBetSlipSubmittingRef.current) {
        handlePageExit();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('pagehide', handlePageHide);
    window.addEventListener('unload', handlePageExit);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('pagehide', handlePageHide);
      window.removeEventListener('unload', handlePageExit);
      handlePageExit();
    };
  }, []);

  useEffect(() => {
    if (betSlip.bets.length === 0) {
      goToPreviousPath();
      return;
    }

    const mCount = _.uniqBy(
      betSlip.bets.filter((b) => b.enabled && !b.banker),
      'providerId'
    ).length;
    setCombinationRelatedBetCount(mCount);

    if (betSlip.system) {
      setCombinationsText(betSlip.combinations.map((c) => ' ' + c + '/' + mCount));
    }
    setNumberOfBets(betSlip.numberOfBets);
    setStakePerBet(betSlip.stakePerBet);
    setTaxAmount(betSlip.tax);
    setMaxProfit(betSlip.maxWinning);
  }, [betSlip]);

  function combinationPress(combination) {
    dispatch(setBetSlipCombination(combination));
  }

  useEffect(() => {
    dispatch(setBetSlipSystem(activeSegment === 2));
  }, [activeSegment]);

  // useEffect(() => {
  //   fetchUser();
  // }, []);

  const deleteAllBets = () => {
    //goToPreviousPath();
    dispatch(removeAllBetSlip());
    // goToPreviousPath();
  };

  function changeBonusBalance(event) {
    if (balance == 0) return;
    setUseBonusBalance(event);
  }

  function changeBetSlipTotal(value) {
    if (
      value > 0 &&
      betSlip.total !== value &&
      value <= 4000 &&
      (useBonusBalance ? value <= refundBonusBalance : value <= balance)
    ) {
      dispatch(setTotal(value));
      setInput(parseFloat(value).toFixed(2));
    }
    if (numpadShow) {
      setNumpadShow(false);
    }
    const categoryLimitsValidation = validateBetSlipCategoryLimit(betSlipEvents, value);
    if (!categoryLimitsValidation.valid) {
      dispatch(setResponseData({ success: false, text: categoryLimitsValidation.text }));
      dispatch(setResultModalShow(true));
      return;
    }
  }

  function checkNumpad() {
    //We want to close the confirmation row if the user changes the stake for accidental confirmation
    setShowConfirmationRow(false);

    stakeInput.current.blur();
    setNumpadShow(true);
  }

  async function placeBet() {
    dispatch(setBetSlipSubmitting(true));
    const customerBalance = await dispatch(fetchBalance());
    if ((!useBonusBalance && customerBalance.payload.balance <= 0) || customerBalance.error) return;

    if (!user) {
      dispatch(setResponseData({ success: false, text: t('pleaseLogin') }));
      dispatch(setResultModalShow(true));
      return;
    }
    const categoryLimitsValidation = validateBetSlipCategoryLimit(betSlipEvents, betSlip.total);
    if (!categoryLimitsValidation.valid) {
      dispatch(setResponseData({ success: false, text: categoryLimitsValidation.text }));
      dispatch(setResultModalShow(true));
      return;
    }

    if (betSlip.system === false && betSlip.bets.length > 15) {
      dispatch(setResponseData({ success: false, text: t('numberofmatch') }));
      dispatch(setResultModalShow(true));
      return;
    }

    if (betSlip.system && betSlip.bets.length > 12) {
      dispatch(setResponseData({ success: false, text: t('systemnumberofmatch') }));
      dispatch(setResultModalShow(true));
      return;
    }

    try {
      const totalStake = betSlip.total;
      const combinations = betSlip.combinations;

      const outcomes = betSlip.bets
        .filter((b) => b.enabled)
        .map((b) => {
          const event = betSlipEvents[b.providerId];
          const markets = event.markets.filter((m) => m.stringId === b.marketStringId);
          const outcomes =
            markets.length > 0 ? markets[0].outcomes.filter((o) => o.id === b.outcomeId) : [];
          const odds =
            outcomes.length > 0
              ? oddsTransformer.transformWithDisplayFormatted(
                  checkIfLiveEvent(b.match),
                  b.match?.isSeason,
                  b?.marketId,
                  new Date(b.match.gameDate),
                  b.odds
                )
              : -1;

          return {
            eventId: b.providerId,
            marketId: b.marketStringId,
            outcomeId: b.outcomeId,
            odds: odds,
            referenceOdds: b.odds,
            banker: b.banker
          };
        });

      // check outcomes odds or reference odds equal 1 or 0 or null and refresh page
      if (outcomes.some((o) => o.odds <= 1 || o.odds === 0 || o.odds === null)) {
        window.location.reload();
      }

      const betSlipType = betSlip.system
        ? EnumBetSlipType.SYSTEM
        : outcomes.length === 1
        ? EnumBetSlipType.SINGLE
        : EnumBetSlipType.MULTIPLE;

      const betRequest = {
        betSlipType: betSlipType,
        totalStake: totalStake,
        isAutomaticCashOutEnabled: false,
        automaticCashOutExecutionThreshold: 500,
        combinations: combinations,
        outcomes: outcomes,
        shopId: getShopId(),
        totalOdds: stakePerBet,
        maxWinning: maxProfit,
        accountType: useBonusBalance ? 5100 : 5000
      };

      if (useBonusBalance) {
        localStorage.setItem('useBonusBalance', 'true');
      } else {
        localStorage.removeItem('useBonusBalance');
      }

      const response = await postPlaceBetReq(betRequest);

      dispatch(fetchBalance());

      const eventGetterFunc = (eventId) => {
        return betSlip.bets.find((b) => b.providerId === eventId);
      };

      if (response.data.success === false) {
        if (response.data.messages[0]?.text.includes('400')) {
          response.data.messages[0].text = t('notenoughbalance');
        } else if (response.data.messages[0]?.code == 'O001') {
          response.data.messages[0].text = t('maxbetslip');
        } else {
          response.data.messages[0].text = parsePlaceBetResponseMsg(
            response.data.messages[0],
            t,
            _currencyFormatter,
            eventGetterFunc,
            locales,
            selectedLocale
          );
        }
      }

      dispatch(
        setResponseData({
          success: response.data.success,
          text: response.data.messages[0]?.text
        })
      );
      if (response.data.success === false) {
        dispatch(setResultModalShow(true));
      } else {
        if (isBetSlipSubmittingRef.current) {
          setShowSuccessModal(true);
        }
      }
    } catch (error) {
      console.log(error);
      dispatch(setResponseData({ success: false, text: error.message }));
      setShowSuccessModal(true);
    }
    dispatch(setBetSlipSubmitting(false));
  }

  const calculateBetSlipRowContainerHeight = () => {
    if (betSlipTop && betSlipTop.current && betSlipBottom && betSlipBottom.current) {
      const betSlipTopHeight = betSlipTop.current.offsetHeight;
      const betSlipBottomHeight = betSlipBottom.current.offsetHeight;
      const headerHeight = 56;
      if (betSlipCenter && betSlipCenter.current) {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        betSlipCenter.current.style.setProperty(
          '--betSlipCenterHeight',
          `calc(100vh - ${
            betSlipTopHeight + betSlipBottomHeight + headerHeight + (isSafari ? 100 : 0)
          }px)`
        );
        betSlipCenter.current.style.setProperty(
          '--betSlipCenterTopOffset',
          `${headerHeight + betSlipTopHeight}px`
        );
        betSlipCenter.current.classList.add('calculated-height');
      }
    }
  };

  useEffect(() => {
    calculateBetSlipRowContainerHeight();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      calculateBetSlipRowContainerHeight();
      if (betSlipCenter && betSlipCenter.current) {
        betSlipCenter.current.scrollTop = betSlipCenter.current.scrollHeight;
      }
    }, 50);
  }, [stakeExpanded, combinationsExpanded, activeSegment]);
  const handleChange = (e) => {
    if (!e.target.value || e.target.value.match(/^\d{1,}(\.\d{0,4})?$/)) {
      if (e.target.value != 0) {
        changeBetSlipTotal(e.target.value);
        setInput(parseFloat(e.target.value).toFixed(2));
      }
    }
  };

  return (
    <div className={props.type == 'desktop' ? 'card-body' : 'bet-slip-container'}>
      <div
        className={
          props.type == 'desktop' ? 'container-fluid bg-green' : 'bet-slip-top container-fluid'
        }
        ref={betSlipTop}>
        <Row className="d-flex justify-content-between align-items-center flex-nowrap fs-8">
          {props.type !== 'desktop' ? (
            <div className="col-auto">
              <div className="text-start cursor-pointer">
                <button
                  className="btn p-0"
                  disabled={betSlip.isBetSlipSubmitting}
                  onClick={() => goToPreviousPath()}>
                  <img src={`${AWS_BUCKET_URL}/Icons/117.png`} alt={'close'} />
                </button>
              </div>
            </div>
          ) : null}

          {props.type == 'desktop' ? (
            <>
              <div className="col-6 text-center">
                <a
                  onClick={() => setActiveSegment(1)}
                  className={
                    'text-dark fs-6' +
                    (activeSegment == 1 ? `${' fw-bold  text-decoration-underline'}` : '')
                  }>
                  {t('single')}
                </a>
              </div>
              <div className="col-6 text-center">
                <a
                  onClick={() => {
                    setActiveSegment(2);
                    setCombinationsExpanded(true);
                  }}
                  className={
                    'text-dark fs-6' +
                    (activeSegment == 2 ? `${' fw-bold  text-decoration-underline'}` : '')
                  }>
                  {t('system')}
                </a>
              </div>
            </>
          ) : (
            <div className="col-auto">
              <a
                onClick={() => setActiveSegment(1)}
                className={
                  'bet-slip-segment-button' +
                  (activeSegment == 1 ? `${' bg-white opacity-100'}` : '')
                }>
                {betSlip.bets.filter((b) => b.enabled).length === 1
                  ? t('single')
                  : `${t('combination')}(${betSlip.bets.filter((b) => b.enabled).length})`}
              </a>
              <a
                onClick={() => {
                  setActiveSegment(2);
                  setCombinationsExpanded(true);
                }}
                className={
                  'bet-slip-segment-button' +
                  (activeSegment == 2 ? `${' bg-white opacity-100'}` : '')
                }>
                {betSlip.bets.filter((b) => b.enabled).length === 1
                  ? t('system')
                  : `${t('system')}(${betSlip.bets.filter((b) => b.enabled).length})`}
              </a>
            </div>
          )}
          {user && props.type != 'desktop' ? (
            refundBonusBalance > 0 ? (
              <div className="col-auto fw-bold d-flex flex-column">
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <span>{t('balance')}:</span>
                  <span className="fs-9">
                    {balance.toLocaleString('de-DE', {
                      minimumFractionDigits: 2
                    }) + ' EUR'}
                  </span>
                </div>
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <span>{t('bonus')}:</span>
                  <span className="fs-9">
                    {refundBonusBalance.toLocaleString('de-DE', {
                      minimumFractionDigits: 2
                    }) + ' EUR'}
                  </span>
                </div>
              </div>
            ) : (
              <div className="col-auto fw-bold d-flex gap-2">
                <div>
                  <span>{t('balance')}:</span>
                  <br />
                  <span className="fs-9">
                    {balance.toLocaleString('de-DE', {
                      minimumFractionDigits: 2
                    }) + ' EUR'}
                  </span>
                </div>
              </div>
            )
          ) : null}
        </Row>
      </div>
      <div className={props.type == 'desktop' ? '' : 'bet-slip-center fs-7'} ref={betSlipCenter}>
        {_.uniqBy(betSlip.bets, 'providerId').map((bet) => {
          return (
            <BetSlipRow
              key={bet.providerId + bet.marketStringId + bet.outcomeId}
              bet={bet}
              type={props.type}
            />
          );
        })}
        <div className="d-flex justify-content-center">
          <a
            className={
              props.type == 'desktop'
                ? 'text-white fs-5'
                : 'text-danger text-decoration-underline fs-8'
            }
            onClick={() => deleteAllBets()}>
            {t('deleteAllBets')}
          </a>
        </div>
      </div>{' '}
      {props.type != 'desktop' ? (
        numpadShow == true ? (
          <Numpad
            onSuccess={changeBetSlipTotal}
            initialValue={betSlip.total && betSlip.total.toFixed(2)}
            unit={betSlip.currencyCode}
          />
        ) : (
          ''
        )
      ) : (
        ''
      )}
      <Row
        ref={betSlipBottom}
        className={
          props.type == 'desktop'
            ? stakeExpanded
              ? 'bet-slip stakeExpanded-height'
              : ''
            : stakeExpanded
            ? 'bet-slip-bottom stakeExpanded-height'
            : 'bet-slip-bottom'
        }>
        {betSlip.system && betSlip.bets.length > 0 && props.type != 'desktop' && (
          <div className="bg-white fs-8">
            <div className="d-flex justify-content-between align-items-center bg-white">
              <div>
                <span>{t('combinations') + ' ' + combinationsText}</span>
              </div>
              <div>
                <a onClick={() => setCombinationsExpanded(!combinationsExpanded)}>
                  <img
                    height={30}
                    width={30}
                    style={{
                      transform: `${!combinationsExpanded ? 'rotate(-90deg)' : 'rotate(90deg)'}`,
                      color: 'rgba(0, 0, 0, 0.7)'
                    }}
                    src={`${AWS_BUCKET_URL}/Icons/114.png`}
                    alt={'expand combination select area'}
                  />
                </a>
              </div>
            </div>
            {combinationsExpanded && (
              <div className="combination-scrollView vstack gap-1 text-start">
                {_.uniqBy(betSlip.bets, 'providerId')
                  .filter((bet) => bet.enabled && !bet.banker)
                  .map((bet, index) => {
                    return (
                      <div key={index}>
                        <a
                          onClick={() => {
                            combinationPress(index + 1);
                          }}
                          className={
                            betSlip.combinations.includes(index + 1) && 'text-success fw-bold'
                          }>
                          <Check2Square
                            fill={betSlip.combinations.includes(index + 1) ? 'green' : 'grey'}
                            size={22}></Check2Square>
                          &nbsp;
                          {index + 1} {'from'} {combinationRelatedBetCount} {'='}
                          {combination(combinationRelatedBetCount, index + 1)} {'Betting'}
                        </a>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        )}
        {betSlip.system && betSlip.bets.length > 0 && props.type == 'desktop' && (
          <div className="combination-scrollView vstack gap-1 text-start">
            {_.uniqBy(betSlip.bets, 'providerId')
              .filter((bet) => bet.enabled && !bet.banker)
              .map((bet, index) => {
                return (
                  <div key={index}>
                    <a
                      onClick={() => {
                        combinationPress(index + 1);
                      }}
                      className={
                        betSlip.combinations.includes(index + 1) && 'text-success fw-bold'
                      }>
                      <Check2Square
                        fill={betSlip.combinations.includes(index + 1) ? 'green' : 'light'}
                        size={22}></Check2Square>
                      &nbsp;
                      <span className="text-white">
                        {index + 1} {'from'} {combinationRelatedBetCount} {'='}
                        {combination(combinationRelatedBetCount, index + 1)} {'Betting'}{' '}
                      </span>
                    </a>
                  </div>
                );
              })}
          </div>
        )}
        <div className="d-flex justify-content-between mt-1 mb-2">
          <div className="align-items-center">
            <span className="text-white fw-bold fs-6 me-2">
              {t('pages.bettingHistoryDetail.details.stake')}
            </span>
            {props.type == 'desktop' ? (
              ''
            ) : (
              <button className="btn p-0" onClick={() => setStakeExpanded(!stakeExpanded)}>
                <img
                  height={30}
                  width={30}
                  className="icon-color"
                  style={{
                    transform: `${!stakeExpanded ? 'rotate(-90deg)' : 'rotate(90deg)'}`,
                    color: 'rgba(0, 0, 0, 0.7)'
                  }}
                  src={`${AWS_BUCKET_URL}/Icons/114.png`}
                  alt={'expand stake area'}
                />
              </button>
            )}
          </div>
          <div className="d-flex align-items-center">
            <span className="ms-auto fs-14 text-white me-2">Eur</span>
            <div className="me-1">
              {props.type != 'desktop' ? (
                <input
                  inputMode="none"
                  ref={stakeInput}
                  onClick={() => checkNumpad()}
                  className="form-control form-control-sm bg-white"
                  value={betSlip.total && ConvertDisplayNumber(betSlip.total)}
                  size={betSlip.total && betSlip.total.toFixed(2).replace('.', ',').length}
                  readOnly
                />
              ) : (
                <input
                  ref={stakeInput}
                  onChange={handleChange}
                  className="form-control form-control-sm bg-white"
                  value={input}
                  type="text"
                />
              )}
            </div>
            <button
              onClick={() => changeBetSlipTotal(betSlip.total - 1)}
              className="btn btn-light btn-sm px-75 me-1">
              -
            </button>
            <button
              onClick={() => changeBetSlipTotal(betSlip.total + 1)}
              className="btn btn-light btn-sm px-75">
              +
            </button>
          </div>
        </div>
        {props.type == 'desktop' ? (
          <div className="fs-7">
            <div className="d-flex justify-content-between">
              <div className="text-light-gray">
                {t('pages.bettingHistoryDetail.details.numberOfBets')}
              </div>
              <div className="fw-bold text-white">{numberOfBets}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="text-light-gray">{t('pages.bettingHistoryDetail.details.tax')}:</div>

              <div className="text-white">{`(${ConvertDisplayNumber(taxAmount)} EUR)`}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="text-light-gray">
                {t('pages.bettingHistoryDetail.details.stakePerBet')}:
              </div>
              <div className="text-white">{`${ConvertDisplayNumber(stakePerBet)} EUR`}</div>
            </div>
          </div>
        ) : stakeExpanded ? (
          <div className="fs-7">
            <div className="d-flex justify-content-between text-white">
              <div>{t('pages.bettingHistoryDetail.details.numberOfBets')}</div>

              <div className="fw-bold">{numberOfBets}</div>
            </div>
            <div className="d-flex justify-content-between text-white">
              <div>{t('pages.bettingHistoryDetail.details.tax')}:</div>

              <div>{ConvertDisplayNumber(taxAmount)} EUR</div>
            </div>
            <div className="d-flex justify-content-between text-white">
              <div>{t('pages.bettingHistoryDetail.details.stakePerBet')}:</div>
              <div>{ConvertDisplayNumber(stakePerBet)} EUR</div>
            </div>
          </div>
        ) : null}
        <Container>
          <div className="d-flex justify-content-between text-white">
            <div className="pb-1">{t('pages.bettingHistoryDetail.details.maxProfit')}</div>
            <div className="text-warning fw-bold">{ConvertDisplayNumber(maxProfit)} EUR</div>
          </div>
          {refundBonusBalance > 0 && props.type == 'desktop' && (
            <Form.Check
              isValid
              type="switch"
              id="custom-switch"
              label={
                <span className="text-white">
                  {t('pages.betslip.useBonusBalance')}
                  <span className="ms-2">{ConvertDisplayNumber(refundBonusBalance)} EUR</span>
                </span>
              }
              className="text-white"
              onChange={(e) => setUseBonusBalance(e.target.checked)}
              checked={useBonusBalance}
            />
          )}
        </Container>
        <Container>
          <div className="d-flex justify-content-center gap-3">
            {showConfirmationRow ? (
              <>
                <Button
                  className="btn btn-danger flex-grow-1"
                  type="button"
                  disabled={betSlip.isBetSlipSubmitting}
                  onClick={() => setShowConfirmationRow(false)}>
                  {t('pages.betslip.cancel')}
                </Button>
                <FetchButton
                  variant="success"
                  className="flex-grow-1 w-25"
                  type="button"
                  onClick={placeBet}
                  disabled={betSlip.isBetSlipSubmitting}
                  fetching={betSlip.isBetSlipSubmitting}>
                  {t('pages.betslip.confirm')}
                </FetchButton>
              </>
            ) : props.type == 'desktop' ? (
              <Button
                variant="success"
                className="bg-green text-white px-1 fw-bold mt-2 w-100"
                type="button"
                onClick={() => setShowConfirmationRow(true)}>
                {t('pages.betslip.placeBet')}
              </Button>
            ) : (
              <div className="w-100">
                {refundBonusBalance > 0 && (
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label={t('pages.betslip.useBonusBalance')}
                    className="text-white"
                    onChange={(e) => changeBonusBalance(e.target.checked)}
                    checked={useBonusBalance}
                  />
                )}
                <Button
                  variant="success"
                  className="bg-danger border border-danger rounded text-white px-1 fw-bold mt-2 w-100"
                  type="button"
                  onClick={() => setShowConfirmationRow(true)}>
                  {t('pages.betslip.placeBet')}
                </Button>
              </div>
            )}
          </div>
        </Container>
      </Row>
      <Modal
        show={betSlip.isBetSlipSubmitting}
        onHide={() => dispatch(setBetSlipSubmitting(true))}
      />
      {betSlip.responseData.success === false ? (
        <Modal show={betSlip.resultModalShow} centered backdrop="static" keyboard={false}>
          <Modal.Body className="p-4 text-center">
            <h5 className="mb-0">
              {betSlip.responseData.successState ? t('Success') : t('Error')}
            </h5>
            <p className="mb-0">{betSlip.responseData.text}</p>
          </Modal.Body>
          <Modal.Footer className="">
            <Button
              variant={betSlip.responseData.successState ? 'success' : 'danger'}
              size="lg"
              className="fs-6 fw-bold w-100"
              onClick={() => dispatch(setResultModalShow(false))}>
              {t('continue')}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal show={showSuccessModal} centered backdrop="static" keyboard={false}>
          <Modal.Body className="p-4 text-center">
            <h5 className="mb-0">{t('pages.betslip.successfulPlaceBet')}</h5>
            <p className="mb-0">{t('pages.betslip.successfulPlaceBetText')}</p>
          </Modal.Body>
          <Modal.Footer className="d-flex gap-3 flex-nowrap">
            <Button
              variant="danger"
              size="lg"
              className="fs-6 w-100"
              onClick={() => {
                setTimer(20);
                setShowSuccessModal(false);
                dispatch(removeAllBetSlip());
              }}>
              {t('pages.betslip.buttonModalClose', [timer])}
            </Button>
            <Button
              variant="success"
              size="lg"
              className="fs-6 fw-bold w-100"
              onClick={handleRestoreClick}>
              {t('pages.betslip.buttonModalRestoreBets')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}
